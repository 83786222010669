import { useEffect } from 'react'
import { setCookie } from 'root/src/client/v2/browser/helpers/cookies'

const ClearRouteComponent = () => {
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (process.browser) {
			const clearRouteCookies = () => {
				setCookie('currentRoute', '', -1)
				setCookie('previousRoute', '', -1)
			}
			window.addEventListener('beforeunload', clearRouteCookies)
			return () => {
				window.removeEventListener('beforeunload', clearRouteCookies)
			}
		}
	}, [])

	return null
}

export default ClearRouteComponent
